<template>
    <div>
        <rxNavBar title="请假审核"></rxNavBar>
        <div class="headerBlock">
            <div class="wait-approve">{{item.approvalStatusStr}}</div>
            <div class="leaveText">
                <div class="leaveMessage orangeColor">{{item.vacationType}}</div>
                <div class="leaveMessage orangeColor">{{item.vacationBeginTime}}&nbsp;至&nbsp;{{item.vacationEndTime}}</div>
                <div class="leaveMessage">申请人：<span class="normalFont">{{item.approvalStaffName}}</span></div>
                <div class="leaveMessage">时长：<span class="normalFont">{{item.vacationTimeLong}}天</span></div>
                <div class="leaveMessage">工作交接人：<span class="normalFont">{{handoverStaff}}</span></div>
                <div class="leaveMessage">事由：<span class="normalFont">{{item.remarks}}</span></div>
            </div>
        </div>
        <div class="imgSwipe" v-if="picList.length>0" >
            <van-swipe :loop="false" :width="115" :show-indicators="false">
                <van-swipe-item   v-for=" (item,i) in picList" :key="item.id">
                    <div class="applyDetailImg">
                        <img  :src="item" @click="onChangeApply(i)"/>
                    </div>
                </van-swipe-item>
                <div class="custom-indicator">
                    {{picList.length}} 图
                </div>
            </van-swipe>
        </div>
        <van-image-preview v-model="showApply" :images="picList"  :start-position="start"></van-image-preview>
        <div class="feedback">审核反馈</div>
        <div class="normalBlock"  style="margin-top: 5px">
            <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
            <span class="blockTitle">审核结果</span>
            <div class="rightText">
                <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
                <span class="content-divide" > | </span>
                <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
            </div>
        </div>

        <div id="addressPanel" class="part-inputpart-row" v-show="isReject" >
            <span class="maker" :class="{'orangeColor':value}" >• </span>
            <span class="part-inputpart-row-header" style="white-space: nowrap">原因</span>
            <span class="content-divides2" >|</span>
            <div class="downMenu" :value="value"  @click="showPicker = true">
                <div class="downMenuValue">{{value}}</div>
                <div :class="showPicker ? 'uparrows' : 'arrows'"></div>
            </div>
            <van-popup v-model="showPicker" position="bottom">
                <van-picker  show-toolbar :columns="refuseReasonList" value-key="dictionaryTitle" @cancel="showPicker = false" @confirm="onConfirm"/>
            </van-popup>
        </div>
<!--        <div class="reject" v-show="isReject">-->
<!--            驳回次数：{{approvalHistoryInfoList.length ? approvalHistoryInfoList.length : '0'}}&nbsp;|&nbsp;-->
<!--&lt;!&ndash;            上次驳回原因：{{approvalHistoryInfoList[0].dictionaryTitle ? approvalHistoryInfoList[0].dictionaryTitle : '暂无'}}&ndash;&gt;-->
<!--        </div>-->
        <div class="displayBlock"  >
            <div><span class="suggestTitle">审批意见</span></div>
            <textarea  class="textArea" v-model="textIdea"></textarea>
        </div>
        <van-button  class="saveButton_Disable" :class="{'saveButton_Enable': isPass|| (isReject&&value)}"
                     :disabled=" !isPass&&!(isReject&&value)" @click="save" >保存
        </van-button>

    </div>
</template>

<script>
    import {
        NavBar, Button, ImagePreview, Image as VanImage, popup, picker, Swipe, SwipeItem
    } from 'vant';
    import BScroll from "better-scroll";
    import RxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    import {responseUtil} from "../../../libs/rongxunUtil";
    import {vacationAgree, vacationDetailInit, vacationRefuse} from "../../../getData/getData";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar"
    export default {
        name: "applyLeave",
        components: {
          rxNavBar,
            [NavBar.name]: NavBar,
            [Button.name]: Button,
            [VanImage.name]:VanImage,
            [popup.name]:popup,
            [picker.name]:picker,
            [Swipe .name]:Swipe,
            [SwipeItem .name]:SwipeItem,
            [ImagePreview.Component.name]: ImagePreview.Component,
            [BScroll.name]:BScroll
        },
        data() {
            return {
                start : '',
                showApply : false,
                picList : [
                    // 'https://img.yzcdn.cn/vant/apple-1.jpg',
                    // 'https://img.yzcdn.cn/vant/apple-2.jpg',
                    // 'https://img.yzcdn.cn/vant/leaf.jpg',
                    // 'https://img.yzcdn.cn/vant/tree.jpg',
                ],
                //审批id
                id : '',
                //审核返回的item信息
                item : {},
                //交接人
                handoverStaff : '',
                //请假员工ID
                approvalApplicantStaff_id : '',
                //拒绝理由
                refuseReasonList : [
                    {
                        //拒绝理由
                        dictionaryTitle : '11',
                    }
                ],
                //拒绝理由ID
                refuseReason_id : '',
                //控制下拉弹窗
                showPicker : false,
                //下拉框默认选项
                value : '',
                //控制驳回时的原因选项显示与隐藏
                isPass:false,
                isReject:false,
                //审批意见
                textIdea : '',
                //驳回记录
                approvalHistoryInfoList : []
            }
        },
        //生命周期初始化
        created() {
            this.id = this.$route.query.id
            this.handoverStaff = this.$route.query.handoverStaff
            this.vacationDetailInit()
        },
        methods: {
            onChangeApply(i) {
                this.start=i
                this.showApply=true
            },
            //审核拒绝接口
            vacationRefuse(){
                let that = this
                let initData = {}
                initData.id = that.id
                initData.staff_id = that.item.approvalApplicantStaff_id
                initData.type = that.item.approvalTypeStr
                initData.remarks = that.item.remarks
                initData.refuseReason_id = that.refuseReason_id
                initData.approvalOpinion = that.textIdea
                vacationRefuse(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        responseUtil.alertMsg(that,'驳回成功')
                        that.skip()
                    })
                })
            },
            //审批同意接口
            vacationAgree(){
                let that = this
                let initData = {}
                initData.id = that.id
                initData.staff_id = that.item.approvalApplicantStaff_id
                initData.type = that.item.approvalTypeStr
                initData.remarks = that.item.remarks
                initData.approvalOpinion = that.textIdea
                vacationAgree(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        responseUtil.alertMsg(that,'通过成功')
                        that.skip()
                    })
                })
            },
            //下拉框选择
            onConfirm(value) {
              if(value){
                this.value = value.dictionaryTitle;
                this.refuseReason_id = value.id
              }
                this.showPicker = false;
            },
            //初始化接口
            vacationDetailInit(){
                let that = this
                let initData = {}
                initData.id = that.id
                vacationDetailInit(initData).then(function (response) {
                    responseUtil.dealResponse(that,response,()=>{
                        that.item = response.data.data.item
                        that.item.vacationTimeLong = Math.ceil(that.item.vacationTimeLong/24)
                        that.refuseReasonList = response.data.data.refuseReasonList
                        that.approvalHistoryInfoList = response.data.data.approvalHistoryInfoList
                        if(response.data.data.addtion.length>0){
                            for(let i=0;i<response.data.data.addtion.length;i++){
                                that.picList[i] = response.data.data.addtion[i].path
                            }
                        }
                        console.log(response.data.data)
                    })
                })
            },
            //点击通过事件
            choosePass(){
                this.isPass = true;
                this.isReject = false;
            },
            //点击驳回事件
            chooseReject(){
                this.isReject = true;
                this.isPass = false;
            },
            //审核同意与拒绝保存按钮
            save(){
                if(this.isPass==true){
                    this.vacationAgree()
                }else{
                    this.vacationRefuse()
                }
            },
            //申请通过和驳回后跳转页面
            skip(){
                this.$router.go(-1)
            }

        },
    }
</script>

<style scoped lang="less">
    *{
        padding: 0;
        margin:0;
    }
    .reject{
        padding-left: 30px;
        padding-top: 6px;
        width: 315px;
        height: 24px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .headerBlock{
        background-color: white;
        border-radius: 6px;
        width:9.2rem;
        height: available;
        margin: 16.2px auto 0px
    }
    .wait-approve{
        width: 43px;
        height: 16px;
        line-height: 17px;
        font-size: 10px;
        text-align: center;
        /*padding-top: 5px;*/
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .leaveText{
        padding-top: 5px;
        padding-left: 15px;
        padding-right: 15px;
        line-height: 25px;
        padding-bottom: 15px;
    }
    .leaveMessage{
        font-size: 14px;
        font-weight: bold;
    }
    .normalFont{
        font-size: 13px;
        font-weight: normal;
        color: #7d7b7b;
    }
    .orangeColor{
        color: rgba(255, 93, 59, 1);
    }
    .feedback{
        padding-top: 30px;
        padding-left: 15px;
        top: 318px;
        width: 60px;
        height: 17px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        text-align: left;
        font-weight: bold;
        font-family: PingFangSC-Semibold;
    }
    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        border-radius: 8px;
    }
    .part-inputpart-dropDownList{
        width: 90%;
        position: absolute;
        left: 19px;
        z-index: 99;
        margin-top: -17px;

    }
    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }
    .part-inputpart-row-header{
        font-weight: bold;
        font-size: 15px;
        width: 30px;
        padding-right: 10px;
    }
    .part-inputpart-row-graytext {
        color: #d8d8d8;
        font-size: 15px;
        width: 220px;
        padding-left: 10px;
    }
    .part-inputpart-row-right{

        float: right;
        padding-right: 24px;
    }
    .part-inputpart-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: white;
        border-radius: 8px;
        width:345px;
        margin: 15px 15px 0px 15px;
    }
    .content-divide {
        width: 1px;
        height: 14px;
        color: #efefef;
    }
    .content-divides1 {

        font-size: 14px;

        color: #EFEFEF;

    }
    .content-divides2{
        font-size: 14px;

        color: #EFEFEF;
    }
    .suggestTitle{
        margin-left: 15px;
        margin-top:15px;
        float: left;
        font-size: 15px;
        font-weight: bold;
        display: block;
    }
    .textArea{
        margin: 45px 10px 20px 15px;
        resize: none;
        padding: 15px;
        height: 90px;
        width: 285px;
        display: block;
        font-size: 14px;
        border-radius: 8px;
        border-style: none;
        background-color: rgba(250, 250, 250, 1);
    }
    .priceUnit{
        color: rgba(153, 153, 153, 0.45);
        font-size: 14px;
        float: right;
        padding-right: 31px;
    }
    .inputPrice{
        color:rgba(255, 93, 59, 1);
        width: 100%;
        height: 28px;
        padding-left: 10px;
        line-height: 28px;
        font-size: 15px;
    //font-weight: bold;
        border: none;
    }
    .passText{
        flex: 1;
        padding-right: 22px;
        color: #999999 ;

    }
    .rejectText{
        padding-left: 30px;
        padding-right: 40px;

    }
    .rightText{
        margin-left:auto;
        font-size: 14px;
        color: #999999;
    }
    .maker{
        color: #999999;
        float: left;
        padding-left: 14px;
        padding-right: 10px;
        font-size: 28px;
    }
    .orangeColor{
        color: rgba(255, 93, 59, 1);
    }
    .choosedStyle{
        color: rgba(255, 93, 59, 1);
        font-weight: bold;
    }
    .blockTitle{
        overflow: hidden;
        float: left;
        font-size: 15px;
        font-weight: bold;
        width: 27%;
    }
    .nav-bar{
        background-color: #f8f8f8;
        border: none;
        font-weight: bold;
    }
    .saveButton_Disable{
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 1.22rem;
        border-radius: 8px;
        margin:30px 15px 50px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .displayBlock {
    //position: relative;
        overflow: hidden;
        background-color: white;
        border-radius: 8px;
        width:340px;
        height: 185px;
        margin: 15px 15px 0px 15px;
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        border-radius: 9px;
        width:345px;
        line-height: 1rem;
        height: 50px;
        margin: 15px 15px 0px 15px;
    }
    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        border-radius: 8px;
    }
    .part-inputpart-dropDownList{
        width: 90%;
        position: absolute;
        left: 19px;
        z-index: 99;
        margin-top: -17px;

    }
    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }
    .part-inputpart-dropDownList-option-selected{
        color: rgba(255, 93, 59, 1);
    }
    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }
    .part-inputpart-row-normaltext{
        color: black;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

    }
    /*下拉*/
    .downMenu{
        display: flex;
        align-items: center;
        width: 70%;
        .arrows{
        height: 0 ;
        width: 0;
          margin-top: 6px;
        border: 3px solid ;
        border-color: black transparent transparent transparent;
         }
      .uparrows {
        height: 0;
        width: 0;
        border: 3px solid;
        border-color: transparent transparent black transparent;
      }
        .downMenuValue{
            margin-left: 10px;
            //width: 95%;
          flex: auto;
            font-size: 14px;
        }
    }
    .normalBlock{
        width: 92%;
        height: 50px;
        /*border: 1px solid red;*/
    }
    .displayBlock{
        width: 92%;
    }
    /*    申请详情图片DIV*/
    .imgSwipe {
        /*position: relative;*/
        height: 108px;
        width: 92%;
        margin: 15px auto;
        background-color: #FFFFFF;
        border-radius: 8px;
        /*图片*/

        .applyDetailImg {
            width: 100px;
            height: 75px;
            border-radius: 8px;
            margin: 15px 0px 18px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e1eaec;
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }
        /*    轮播的显示图片数*/
        .custom-indicator{
            position: absolute;
            left: 15px;
            top: 20px;
            padding: 1px 7px;
            font-size: 12px;
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            color: white;
        }
    }
</style>